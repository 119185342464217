import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui'
import baseTheme from '@solid-ui-theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { FormContextProvider } from '@solid-ui-components/ContentForm'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'
import ColorMode from '@solid-ui-components/ColorMode'
import Helmet from 'react-helmet'

const Layout = ({ children, pageContext = {}, location, theme = {} }) => {
  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <Helmet>
        <script type="text/javascript" charSet="UTF-8" src="//cdn.cookie-script.com/s/ca0a898943127af1f267d8fdbbadfcf7.js"></script>
        <meta name="verify-admitad" content="b7bb71a2d5" />
        <meta name="ga-site-verification" content="ekFfocoTU8xyGXbPr-X26oPp" />
      </Helmet>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />
                <ColorMode />
                <Box variant='layout.body'>{children}</Box>
              </Flex>
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}

export default Layout
